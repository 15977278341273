import React from "react"
import Layout from "../components/layout"
export default function NotFound() {
  return (
    <Layout>
    <div className="container">
      <h1>Page non trouvée</h1>
      <p>Oops, nous ne trouvons pas cette page!</p>
    </div>
    </Layout>
  )
}